import LayoutTypes from './layout.types';

export default {

    setFooterLinks: (footerLinks: any[]) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.SET_FOOTER_LINKS, payload: footerLinks });
        };
    },

    addFooterLinks: (footerLinks: any[]) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.ADD_FOOTER_LINK, payload: footerLinks });
        };
    },

    setNavLinks: (navLinks: any[]) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.SET_NAVBAR_LINKS, payload: navLinks });
        };
    },

    setLayoutLoading: (isLoading: boolean) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: isLoading });
        };
    },

    setNavigationStatus: (isFromSMSLink: boolean) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.SET_IS_FROM_SMS_LINK, payload: isFromSMSLink });
        };
    },
    addNavLinks: (navLinks: any[]) => {
        return (dispatch: any) => {
            dispatch({ type: LayoutTypes.ADD_NAVBAR_LINK, payload: navLinks });
        };
    }

}